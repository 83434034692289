import {
    ref,
    unref,
    reactive,
    computed,
    onMounted
} from "@vue/composition-api";
import differenceBy from "lodash/differenceBy";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";

import ProjectTypedUser from "@/modules/Project/classes/ProjectTypedUser";

export default function useAssignToProject(roleType, { props, context }) {
    let typedUser = new ProjectTypedUser(unref(props.type), roleType);

    // Data
    const isToAssignLoading = ref(false);
    const toAssign = ref([]);
    const toAssignPagination = reactive({
        page: 1,
        total: 1
    });

    const isAssignedLoading = ref(false);
    const assigned = ref([]);
    const assignedPagination = reactive({
        page: 1,
        total: 1
    });

    // Mounted
    onMounted(() => {
        init();
    });

    // Computed
    const availableToAssign = computed(() => {
        let users = differenceBy(
            toAssign.value,
            assigned.value,
            (user) => user.id
        );
        return users;
    });

    // Methods
    async function init() {
        loadToAssign();
        if (props.isEdit) {
            loadAssigned();
        }
    }

    async function loadToAssign(search = "") {
        const perPage = 15;

        let params = {
            queries: {
                "name[partial]": search
            },
            page: toAssignPagination.page,
            perPage: perPage
        };

        try {
            isToAssignLoading.value = true;

            let res = props.isEdit
                ? await typedUser.getUnassignedUsers()(
                      unref(props.projectId),
                      params
                  )
                : await typedUser.getUsers()(params);
            setToAssignData(res);

            isToAssignLoading.value = false;
        } catch (error) {
            isToAssignLoading.value = false;
            context.root.$notify({
                group: "alert",
                type: "error",
                title: "Error",
                text: `Failed to fetch available ${typedUser.label}. Please try again later.`
            });
        }
    }

    async function loadNextToAssign(scrollState) {
        let page = toAssignPagination.page;
        let total = toAssignPagination.total;
        // Load more if there are more pages
        if (page < total) {
            toAssignPagination.page++;
            await loadToAssign();
            scrollState.loaded();
        } else {
            scrollState.loaded();
            scrollState.complete();
        }
    }

    function setToAssignData(res) {
        try {
            // Append data to current user list
            toAssign.value = [...toAssign.value, ...cloneDeep(res.data)];

            // Update total page
            toAssignPagination.total = res.meta.pagination.total_pages;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    function resetToAssignState() {
        toAssign.value = [];
        Object.assign(
            toAssignPagination,
            reactive({
                page: 1,
                total: 1
            })
        );
    }

    function searchToAssign(search) {
        resetToAssignState();
        loadToAssign(search);
    }

    function assignUser(user) {
        assigned.value.push(user);
        emitValue();
    }

    function emitValue() {
        context.emit(
            "input",
            assigned.value.map((user) => props.mapValue(user))
        );
        context.emit(
            "update",
            assigned.value
                .filter((user) => !user.existing)
                .map((user) => props.mapValue(user))
        );
    }

    async function removeAssigned(user) {
        if (user.existing) {
            await removeExistingUser(user);
        } else {
            removeUser(user);
        }
    }

    function removeUser(user) {
        assigned.value = assigned.value.filter((b) => b.id != user.id);
        emitValue();
    }

    async function removeExistingUser(user) {
        let confirm = await context.root.$confirm({
            type: "alert",
            title: `Remove Allocated ${startCase(typedUser.label)}`,
            message: `Are you sure you want to remove ${user.name} from the ${typedUser.label} allocation?`,
            confirmText: "Remove"
        });

        if (confirm) {
            try {
                await typedUser.removeAssignedUser()(
                    unref(props.projectId),
                    typedUser.getRemoveParam(user.id)
                );
                removeUser(user);
                context.root.$notify({
                    group: "alert",
                    type: "success",
                    title: "Success",
                    text: `${user.name} has been removed from the ${typedUser.label} allocation successfully.`
                });

                resetToAssignState();
                await loadToAssign();
            } catch (error) {
                context.root.$notify({
                    group: "alert",
                    type: "error",
                    title: "Error",
                    text: `Failed to remove ${typedUser.label}. Please try again later.`
                });
            }
        }
    }

    async function loadAssigned(search = "") {
        const perPage = 15;

        let params = {
            queries: {
                "name[partial]": search
            },
            page: assignedPagination.page,
            perPage: perPage
        };

        try {
            isAssignedLoading.value = true;
            let res = await typedUser.getAssignedUsers()(
                unref(props.projectId),
                params
            );

            setAssignedData(res);

            isAssignedLoading.value = false;
        } catch (error) {
            isAssignedLoading.value = false;
            context.root.$notify({
                group: "alert",
                type: "error",
                title: "Error",
                text: `Failed to fetch assigned ${typedUser.label}. Please try again later.`
            });
        }
    }
    function setAssignedData(res) {
        // Append data to current user list
        assigned.value = [
            ...assigned.value,
            ...cloneDeep(res.data).map((user) => ({
                ...user,
                existing: true
            }))
        ];
        // Emit value
        emitValue();
        // Update total page
        assignedPagination.total = res.meta.pagination.total_pages;
    }
    async function loadNextAssigned(scrollState) {
        let page = assignedPagination.page;
        let total = assignedPagination.total;
        // Load more if there are more pages
        if (page < total) {
            assignedPagination.page++;
            await loadToAssign();
            scrollState.loaded();
        } else {
            scrollState.loaded();
            scrollState.complete();
        }
    }

    function resetAssignedState() {
        assigned.value = [];
        Object.assign(
            assignedPagination,
            reactive({
                page: 1,
                total: 1
            })
        );
    }

    async function reloadAssigned() {
        resetAssignedState();
        await loadAssigned();
    }

    return {
        // data
        isToAssignLoading,
        toAssign,
        toAssignPagination,

        isAssignedLoading,
        assigned,
        assignedPagination,
        // computed
        availableToAssign,
        // methods
        init,
        loadToAssign,
        loadNextToAssign,
        setToAssignData,
        resetToAssignState,
        searchToAssign,
        assignUser,
        removeAssigned,

        loadAssigned,
        setAssignedData,
        loadNextAssigned,
        reloadAssigned
    };
}
