import ProjectAPI from "@/modules/Project/api/project";
import TypedUser from "./TypedUser";

export default class ProjectTypedUser extends TypedUser {
    projectAPI;

    constructor(apiType, roleType) {
        super(roleType);
        this.projectAPI = new ProjectAPI(apiType);
    }

    getUsers() {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return this.projectAPI.getBankers;
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return this.projectAPI.getLawyers;
        }
    }
    getUnassignedUsers() {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return this.projectAPI.getUnassignedBankers;
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return this.projectAPI.getUnassignedLawyers;
        }
    }
    getAssignedUsers() {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return this.projectAPI.getAssignedBankers;
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return this.projectAPI.getAssignedLawyers;
        }
    }
    removeAssignedUser() {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return this.projectAPI.removeAssignedBanker;
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return this.projectAPI.removeAssignedLawyer;
        }
    }

    getRemoveParam(id) {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return {
                bankerId: id
            };
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return {
                lawyerId: id
            };
        }
    }
}
